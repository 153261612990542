exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aboutus-jsx": () => import("./../../../src/pages/aboutus.jsx" /* webpackChunkName: "component---src-pages-aboutus-jsx" */),
  "component---src-pages-getintouch-jsx": () => import("./../../../src/pages/getintouch.jsx" /* webpackChunkName: "component---src-pages-getintouch-jsx" */),
  "component---src-pages-holding-jsx": () => import("./../../../src/pages/holding.jsx" /* webpackChunkName: "component---src-pages-holding-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ourmortgages-jsx": () => import("./../../../src/pages/ourmortgages.jsx" /* webpackChunkName: "component---src-pages-ourmortgages-jsx" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policyPage.js" /* webpackChunkName: "component---src-templates-policy-page-js" */)
}

