import * as React from "react";
import { createTheme } from "@mui/material";
import { listItemClasses } from "@mui/material/ListItem";
import { listItemTextClasses } from "@mui/material/ListItemText";
import { tabClasses } from "@mui/material/Tab";
import { darken } from "@mui/material/styles";
import ArrowRightIcon from "@mui/icons-material/East";

export const BRAND_PRIMARY_COLOR = "#003232";
export const BRAND_SECONDARY_COLOR = "#D14600";
export const BRAND_TERTIARY_COLOR = "#00C8C8";

const defaultTheme = createTheme();

const palette = {
    primary: {
        main: BRAND_PRIMARY_COLOR
    },
    secondary: {
        main: BRAND_SECONDARY_COLOR
    },
    info: {
        main: BRAND_TERTIARY_COLOR
    },
    common: {
        grey: "#554E50"
    },
    text: {
        primary: "#201A1C"
    },
    divider: "rgba(255, 255, 255, 0.54)"
};

const theme = createTheme({
    breakpoints: {
        values: {
            ...defaultTheme.breakpoints.values,
            // lg: 1440
            maxContainer: 1440
        }
    },
    typography: {
        fontFamily: ["Montserrat", "Hind", "sans-serif"].join(","),
        h1: {
            fontSize: "3.125rem",
            fontWeight: 400,
            margin: 0
        },
        h2: {
            fontSize: "2.25rem",
            fontWeight: 500,
            margin: 0,
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: "1.125rem"
            }
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: 400,
            margin: 0,
            color: palette.common.grey
        },
        p: {
            lineHeight: "150%",
            fontWeight: "300"
        },
        button: {
            textTransform: "none",
            fontFamily: "Hind"
        },
        body1: {
            fontWeight: 300,
            fontSize: "1.25rem"
        },
        subtitle1: {
            fontSize: "1.1rem"
        },
        subtitle2: {
            fontSize: "1rem"
        }
    },
    palette
});

theme.components = {
    MuiCssBaseline: {
        styleOverrides: {
            li: {
                "&::marker": {
                    color: palette.secondary.main
                }
            },
            a: {
                color: "#0071C2"
            }
        }
    },
    MuiButton: {
        defaultProps: {
            color: "primary",
            disableElevation: true,
            variant: "contained"
        },
        styleOverrides: {
            containedPrimary: {
                backgroundColor: BRAND_TERTIARY_COLOR,
                color: palette.text.primary,
                "&:hover": {
                    color: "#fff",
                    backgroundColor: darken(BRAND_TERTIARY_COLOR, 0.5)
                }
            },
            containedSecondary: {
                backgroundColor: BRAND_SECONDARY_COLOR
            },
            contained: {
                fontWeight: 600,
                // borderRadius: "33px",
                fontSize: "1rem",
                padding: theme.spacing(1.25, 2),
                width: "fit-content"
            },
            containedSizeLarge: {
                fontWeight: "bold",
                lineHeight: "inherit",
                padding: theme.spacing(1.5, 2.25)
            }
        }
    },
    MuiTabs: {
        styleOverrides: {
            flexContainerVertical: {
                alignItems: "flex-start",
                width: "100%",
                height: "100%"
            },
            indicator: {
                display: "inline-block",
                position: "relative",
                width: "100%",
                backgroundColor: "transparent",
                borderRight: `2px solid ${theme.palette.grey["300"]}`,
                top: "0 !important"
            }
        }
    },
    MuiTab: {
        defaultProps: {
            icon: <ArrowRightIcon />,
            iconPosition: "start"
        },
        styleOverrides: {
            vertical: {
                border: `2px solid ${theme.palette.grey["300"]}`,
                borderLeft: 0,
                borderBottom: 0,
                fontSize: "1.125rem",
                justifyContent: "flex-start",
                opacity: 1,
                width: "100%",
                maxWidth: "100%",
                "&.Mui-selected": {
                    color: theme.palette.common.black,
                    borderRight: 0
                },
                [`&:not(.Mui-selected) .${tabClasses.iconWrapper}`]: {
                    display: "none"
                },
                "&:last-child": {
                    borderBottom: `2px solid ${theme.palette.grey["300"]}`
                }
            },
            root: {
                border: `2px solid ${theme.palette.grey["300"]}`,
                borderLeft: 0,
                borderBottom: 0,
                fontSize: "1.125rem",
                justifyContent: "flex-start",
                opacity: 1,
                width: "100%",
                maxWidth: "100%",
                "&.Mui-selected": {
                    color: theme.palette.common.black,
                    borderRight: 0
                },
                [`&:not(.Mui-selected) .${tabClasses.iconWrapper}`]: {
                    display: "none"
                },
                "&:last-child": {
                    borderBottom: `2px solid ${theme.palette.grey["300"]}`
                }
            }
        }
    },
    MuiList: {
        variants: [
            {
                props: { component: "ul" },
                style: {
                    listStyle: "none"
                }
            }
        ]
    },
    MuiListItem: {
        variants: [
            {
                props: { component: "li" },
                style: {
                    [`.${listItemTextClasses.root}`]: {
                        paddingLeft: "1.25em",
                        ...theme.typography.body1
                    },
                    ":before": {
                        content: "'\\2022'",
                        fontSize: "4rem",
                        position: "absolute",
                        top: "-1.65rem"
                    },
                    [`&.${listItemClasses.dense}:before`]: {
                        top: "-1.9rem"
                    }
                }
            },
            {
                props: { variant: "dashed" },
                style: {
                    ":before": {
                        content: "'\\2218'"
                    }
                }
            }
        ]
    },
    MuiListItemText: {
        defaultProps: {
            disableTypography: true
        }
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderRightWidth: "medium"
            }
        }
    },
    MuiOutlinedInput: {
        defaultProps: {
            size: "small"
        },
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.text.primary
            },
            // Remove colour overrides for browser auto-filled fields
            input: {
                "&:-webkit-autofill": {
                    "-webkit-box-shadow": "none",
                    "-webkit-text-fill-color": "initial"
                }
            }
        }
    },
    MuiInputLabel: {
        defaultProps: {
            shrink: true
        },
        styleOverrides: {
            root: {
                fontWeight: 600,
                marginTop: `-${theme.spacing(2.5)}`,
                marginLeft: `-${theme.spacing(1.5)}`
            }
        }
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                fontSize: "1.125rem",
                color: theme.palette.text.primary,
                ...theme.typography.subtitle2,
                fontWeight: 500,
                marginBottom: theme.spacing(1)
            }
        }
    },
    MuiFormControl: {
        defaultProps: {
            fullWidth: true
        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                marginLeft: theme.spacing(0.5),
                marginTop: theme.spacing(1),
                ...theme.typography.subtitle2
            }
        }
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                [theme.breakpoints.between("md", "xl")]: {
                    maxWidth: "85%"
                }
            }
        }
    }
};

theme.components.MuiFormControlLabel = {
    styleOverrides: {
        label: {
            ...theme.components.MuiFormLabel.styleOverrides.root,
            marginBottom: 0
        }
    }
};

export const darkTheme = createTheme({
    ...theme,
    palette: {
        ...palette,
        mode: "dark"
    }
});

export default theme;
