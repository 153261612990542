module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-analytics@5.12.0_gatsby@4.25.7_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-","head":true,"anonymize":true,"respectDNT":false,"pageTransitionDelay":0,"defer":false,"cookieDomain":"none","exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@4.25.7_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P28L29Z","includeInDevelopment":true,"routeChangeEventName":"VirtualPageView","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.14.18_gatsby@4.25.7/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.11.1_gatsby@4.25.7/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@4.25.7_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ModaMortgages","short_name":"website","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a89600fafb82d69fc79c89a248d6ce89"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.7_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
